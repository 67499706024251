<template>
  <div>
    <v-row class="align-center">
      <v-col cols="6">
        <app-text-field label="level" v-model="searchParams.level" clearable />
      </v-col>
      <v-col cols="6">
        <app-text-field label="extra" v-model="searchParams.extra" clearable />
      </v-col>
      <v-col cols="6">
        <app-text-field
          label="message"
          v-model="searchParams.message"
          clearable
        />
      </v-col>
      <v-col cols="6">
        <app-text-field
          label="context"
          v-model="searchParams.context"
          clearable
        />
      </v-col>
      <v-col cols="6">
        <app-text-field
          label="channel"
          v-model="searchParams.channel"
          clearable
        />
      </v-col>
      <v-col cols="6">
        <app-text-field
          label="formatted"
          v-model="searchParams.formatted"
          clearable
        />
      </v-col>
      <v-col cols="6">
        <app-text-field
          label="level_name"
          v-model="searchParams.level_name"
          clearable
        />
      </v-col>
      <v-col cols="6">
        <app-text-field
          label="record_datetime"
          v-model="searchParams.date_start"
          type="date"
        />
      </v-col>
      <v-col cols="6">
        <v-btn @click="select()" class="ml-auto" color="success">
          Pesquisar
        </v-btn>
      </v-col>

      <v-col class="d-flex justify-end">
        <v-menu transition="slide-y-transition" bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn color="primary" text v-bind="attrs" v-on="on">
              <v-icon left>mdi-pencil</v-icon>
              Editar Colunas
            </v-btn>
          </template>
          <v-list>
            <v-list-item v-for="(allHeader, i) in allHeaders" :key="i">
              <v-list-item-title>{{ allHeader.text }}</v-list-item-title>
              <v-list-item-action>
                <v-checkbox v-model="headers" :value="allHeader" />
              </v-list-item-action>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <app-pagination @click="select($event)" :data="systemLogs">
          <v-data-table
            dense
            :headers="headers"
            :items="systemLogs.data"
            hide-default-footer
            disable-sort
            :items-per-page="-1"
            @click:row="viewLog($event.id)"
          >
            <template v-slot:[`item.level`]="{ item }">
              <code>{{ item.level }}</code>
            </template>
            <template v-slot:[`item.extra`]="{ item }">
              <code>{{ item.extra }}</code>
            </template>
            <template v-slot:[`item.message`]="{ item }">
              <code>{{ item.message }}</code>
            </template>
            <template v-slot:[`item.context`]="{ item }">
              <code>{{ item.context }}</code>
            </template>
            <template v-slot:[`item.channel`]="{ item }">
              <code>{{ item.channel }}</code>
            </template>
            <template v-slot:[`item.formatted`]="{ item }">
              <code>{{ item.formatted }}</code>
            </template>
            <template v-slot:[`item.level_name`]="{ item }">
              <code>{{ item.level_name }}</code>
            </template>
            <template v-slot:[`item.record_datetime`]="{ item }">
              <code>{{ item.record_datetime }}</code>
            </template>
            <template v-slot:[`item.created_at`]="{ item }">
              {{ $format.dateTimeBr(item.created_at) }}
            </template>
          </v-data-table>
        </app-pagination>
      </v-col>
    </v-row>
    <SystemLogViewDialog ref="SystemLogViewDialog" />
  </div>
</template>
<script>
import SystemLogViewDialog from "@/components/admin/sections/SystemLogViewDialog.vue";
export default {
  components: { SystemLogViewDialog },

  data() {
    return {
      headers: [
        { text: "id", value: "id", align: "center" },
        { text: "message", value: "message" },
        { text: "created_at", value: "created_at", align: "center" },
      ],

      allHeaders: [
        { text: "level", value: "level" },
        { text: "extra", value: "extra" },
        { text: "message", value: "message" },
        { text: "context", value: "context" },
        { text: "channel", value: "channel" },
        { text: "formatted", value: "formatted" },
        { text: "level_name", value: "level_name" },
        { text: "record_datetime", value: "record_datetime" },
      ],

      systemLogs: {},

      searchParams: {
        date_start: new Date().toISOString().substr(0, 10),
      },
    };
  },
  mounted() {
    // this.select();
  },

  methods: {
    select(page) {
      this.searchParams.page = page;
      this.$loading.start();
      this.$http
        .index("admin/system-log", this.searchParams)
        .then((response) => {
          this.$loading.finish();
          this.systemLogs = response.systemLogs;
        })
        .catch((error) => this.$loading.finish());
    },

    viewLog(id) {
      this.$refs.SystemLogViewDialog.open(id);
    },
  },
};
</script>
<style lang="scss" scoped>
.code {
  background-color: #282a36;
  code {
    white-space: pre-wrap;
    word-wrap: break-word;
    color: #f8f8f2;
    font-family: courier, monospace;
    word-break: break-all;
    tab-size: 4;
    -moz-tab-size: 4;
  }
}
</style>
