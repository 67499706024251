<template>
  <v-dialog v-model="dialog" scrollable fullscreen>
    <v-card>
      <v-card-title class="d-flex align-baseline">
        <span> #{{ log.id }} - {{ log.message }} </span>
      </v-card-title>
      <v-card-subtitle class="d-flex align-baseline justify-space-between">
        <span> record_datetime: {{ log.record_datetime }}</span>
        <span> created_at: {{ log.created_at }}</span>
      </v-card-subtitle>

      <v-card-text>
        <div>
          level
          <AppCode :value="log.level" />
        </div>
        <br />
        <div>
          extra
          <AppCode :value="log.extra" />
        </div>
        <br />
        <div>
          message
          <AppCode :value="log.message" />
        </div>
        <br />
        <div>
          context
          <AppCode :value="log.context" />
        </div>
        <br />
        <div>
          channel
          <AppCode :value="log.channel" />
        </div>
        <br />
        <div>
          formatted
          <AppCode :value="log.formatted" />
        </div>
        <br />
        <div>
          level_name
          <AppCode :value="log.level_name" />
        </div>
        <br />
        <div>
          record_datetime
          <AppCode :value="log.record_datetime" />
        </div>
        <br />
      </v-card-text>
      <v-card-actions>
        <v-btn @click="dialog = false" text> Voltar </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      log: {},
      baseLog: {
        message: null,
        context: null,
        level: null,
        level_name: null,
        channel: null,
        record_datetime: null,
        extra: null,
        formatted: null,
      },
      dialog: false,
    };
  },
  computed: {},

  methods: {
    async open(id) {
      this.log = JSON.parse(JSON.stringify(this.baseLog));
      this.$loading.start();
      await this.$http
        .show("admin/system-log", id)
        .then(async (response) => {
          this.log = JSON.parse(JSON.stringify(response.log));
          this.dialog = true;
          await this.$loading.finish();
        })
        .catch((error) => this.$loading.finish());
    },
  },
};
</script>

<style lang="scss" scoped></style>
